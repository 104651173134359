import React, { useState } from "react";
import ShopImage1 from "../assets/PROFESSIONAL WORKER/Accounting-Website/Home.png";
import ShopImage2 from "../assets/PROFESSIONAL WORKER/Admin-Website/Admin-dasboard-panel.png";
import ShopImage3 from "../assets/PROFESSIONAL WORKER/Freelance-Portfolio/Personal-Portfolio.png";

function ProfessionalWorker() {
  const projects = [
    {
      title: "Professional Accountant Website",
      image: ShopImage1,
      description:
        "A comprehensive platform designed for accountants to showcase their services, manage clients, and streamline financial processes.",
      rating: 4.5,
    },
    {
      title: "Professional Admin Website",
      image: ShopImage2,
      description:
        "A dedicated platform for administrators to efficiently manage tasks, connect with businesses, and enhance productivity.",
      rating: 3.5,
    },
    {
      title: "Professional Portfolio Website",
      image: ShopImage3,
      description:
        "A sleek and modern portfolio website tailored to showcase professional achievements, skills, and projects with impact.",
      rating: 4,
    },
  ];

  const [likedProjects, setLikedProjects] = useState(
    Array(projects.length).fill(false)
  );

  // Toggle like state
  const toggleLike = (index) => {
    const updatedLikes = [...likedProjects];
    updatedLikes[index] = !updatedLikes[index];
    setLikedProjects(updatedLikes);
  };

  // Helper function to render stars
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const totalStars = 5;

    return (
      <div className="flex items-center">
        {Array.from({ length: fullStars }).map((_, index) => (
          <span key={`full-${index}`} className="text-yellow-400 text-lg">
            ⭐
          </span>
        ))}
        {halfStar && <span className="text-yellow-400 text-lg">⭐️</span>}
        {Array.from({
          length: totalStars - fullStars - (halfStar ? 1 : 0),
        }).map((_, index) => (
          <span key={`empty-${index}`} className="text-gray-300 text-lg">
            ⭐
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-6 px-6 md:px-12 lg:px-6">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">
          Professional Workers
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div
              key={project.title}
              className="bg-white rounded-lg shadow-md overflow-hidden relative"
            >
              {/* Heart Icon */}
              <div
                onClick={() => toggleLike(index)}
                className="absolute top-4 right-4 cursor-pointer text-black h-10 w-10 items-center justify-center flex rounded-full shadow-md bg-white"
              >
                {likedProjects[index] ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78L12 21.23l8.84-8.84a5.5 5.5 0 000-7.78z"
                    />
                  </svg>
                )}
              </div>

              <img
                src={project.image}
                alt={project.title}
                className="w-full object-cover h-48 md:h-56 lg:h-64"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-gray-600">{project.description}</p>
                <div className="mt-2">{renderStars(project.rating)}</div>
                <button className="w-full bg-[#EC2A02] hover:bg-[#724137] text-white font-bold py-2 px-4 rounded mt-4">
                  <a href="/contact">Order Now</a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfessionalWorker;
