import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://63f394b1-311a-4378-b722-6ae57121fb46.us-east-1.cloud.genez.io/blogs"
        );
        setBlogs(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading)
    return <p className="text-center text-gray-500">Loading blogs...</p>;
  if (error) return <p className="text-center text-red-500">Error: {error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">Blogs</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="border p-4 rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
            onClick={() => navigate(`/blogs/${blog.id}`)} // Navigate on click
          >
            {blog.Image && (
              <img
                src={blog.Image}
                alt={blog.name || "Blog Image"}
                className="w-full h-48 object-cover rounded-md mb-2"
              />
            )}
            <h2 className="text-xl font-semibold">{blog.name}</h2>
            <p className="text-gray-600">
              {new Date(blog.createdAt).toDateString()}
            </p>
            <p className="mt-2">{blog.content.substring(0, 100)}...</p>
            <button
              className="mt-3 text-blue-500 font-semibold underline"
              onClick={(e) => {
                e.stopPropagation(); // Prevent parent div click event
                navigate(`/blogs/${blog.id}`);
              }}
            >
              Read More →
            </button>
          </div>
        ))}
      </div> 
    </div>
  );
};

export default Blog;
