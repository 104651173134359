import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://63f394b1-311a-4378-b722-6ae57121fb46.us-east-1.cloud.genez.io/blogs/${id}`
        );
        setBlog(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading)
    return <p className="text-center text-gray-500">Loading blog...</p>;
  if (error) return <p className="text-center text-red-500">Error: {error}</p>;

  return (
    <div className="container mx-auto p-4 max-w-3xl">
      <button
        onClick={() => navigate(-1)}
        className="text-blue-500 underline mb-4"
      >
        ← Back to Blogs
      </button>
      <div className="border p-6 rounded-lg shadow-md">
        {/* Display Image */}
        {blog.Image && (
          <img
            src={blog.Image}
            alt={blog.name}
            className="w-full h-64 object-cover rounded-md mb-4"
          />
        )}
        <h1 className="text-3xl font-bold">{blog.name}</h1>
        <p className="text-gray-600">
          By {blog.author || "Unknown"} on{" "}
          {new Date(blog.createdAt).toDateString()}
        </p>
        <p className="mt-4 text-lg">{blog.content}</p>
      </div>
    </div>
  );
};

export default BlogDetails;
